import service from './service'
import { CONTENT_TYPE } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'
import CryptoJs from 'crypto-js'

const getTokenSign = (url: string) => {
  const userStore = useUserStoreWithOut()
  const userInfo = userStore.getUserInfo
  const PATH_URL = import.meta.env.VITE_API_BASE_PATH
  return {
    [userStore.getTokenKey ?? 'Token']: userStore.getToken ?? '',
    [userStore.getSignKey ?? 'Sign']: userInfo
      ? CryptoJs.MD5(
          CryptoJs.enc.Utf8.parse(
            '!@#' +
              userInfo.username +
              '$%^' +
              userStore.getToken +
              '&*(' +
              new URL(
                (PATH_URL.substring(PATH_URL.length - 1, PATH_URL.length) === '/'
                  ? PATH_URL
                  : PATH_URL.substring(0, PATH_URL.length) + '/') +
                  (url.substring(0, 1) === '/' ? url.substring(1) : url)
              ).pathname +
              ')_+'
          )
        ).toString()
      : ''
  }
}
const request = (option: AxiosConfig) => {
  const { url, method, params, data, headers, responseType } = option

  const headersObj = {
    'Content-Type': CONTENT_TYPE,
    ...getTokenSign(url),
    ...headers
  }

  return service.request({
    url: url,
    method,
    params,
    data: data,
    responseType: responseType,
    headers: headersObj
  })
}

export default {
  get: <T = any>(option: AxiosConfig) => {
    return request({ method: 'get', ...option }) as Promise<IResponse<T>>
  },
  post: <T = any>(option: AxiosConfig) => {
    return request({ method: 'post', ...option }) as Promise<IResponse<T>>
  },
  delete: <T = any>(option: AxiosConfig) => {
    return request({ method: 'delete', ...option }) as Promise<IResponse<T>>
  },
  put: <T = any>(option: AxiosConfig) => {
    return request({ method: 'put', ...option }) as Promise<IResponse<T>>
  },
  cancelRequest: (url: string | string[]) => {
    return service.cancelRequest(url)
  },
  cancelAllRequest: () => {
    return service.cancelAllRequest()
  },
  getTokenSign: (url: string) => {
    return getTokenSign(url)
  }
}
