/**
 *
 * @param component 需要注册的组件
 * @param alias 组件别名
 * @returns any
 */
export const withInstall = <T>(component: T, alias?: string) => {
  const comp = component as any
  comp.install = (app: any) => {
    app.component(comp.name || comp.displayName, component)
    if (alias) {
      app.config.globalProperties[alias] = component
    }
  }
  return component as T & Plugin
}

/**
 * @param str 需要转下划线的驼峰字符串
 * @returns 字符串下划线
 */
export const humpToUnderline = (str: string): string => {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase()
}

/**
 * @param str 需要转驼峰的下划线字符串
 * @returns 字符串驼峰
 */
export const underlineToHump = (str: string): string => {
  if (!str) return ''
  return str.replace(/\-(\w)/g, (_, letter: string) => {
    return letter.toUpperCase()
  })
}

/**
 * 驼峰转横杠
 */
export const humpToDash = (str: string): string => {
  return str.replace(/([A-Z])/g, '-$1').toLowerCase()
}

export const setCssVar = (prop: string, val: any, dom = document.documentElement) => {
  dom.style.setProperty(prop, val)
}

export const getCssVar = (prop: string, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(prop)
}

/**
 * 查找数组对象的某个下标
 * @param {Array} ary 查找的数组
 * @param {Functon} fn 判断的方法
 */
export const findIndex = <T = Recordable>(ary: Array<T>, fn: Fn): number => {
  if (ary.findIndex) {
    return ary.findIndex(fn)
  }
  let index = -1
  ary.some((item: T, i: number, ary: Array<T>) => {
    const ret: T = fn(item, i, ary)
    if (ret) {
      index = i
      return ret
    }
  })
  return index
}

export const trim = (str: string) => {
  return str.replace(/(^\s*)|(\s*$)/g, '')
}

/**
 * @param {Date | number | string} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time: Date | number | string, fmt: string) {
  if (!time) return ''
  else {
    const date = new Date(time)
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds()
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
        )
      }
    }
    return fmt
  }
}

/**
 * 生成随机字符串
 */
export function toAnyString() {
  const str: string = 'xxxxx-xxxxx-4xxxx-yxxxx-xxxxx'.replace(/[xy]/g, (c: string) => {
    const r: number = (Math.random() * 16) | 0
    const v: number = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString()
  })
  return str
}

/**
 * 首字母大写
 */
export function firstUpperCase(str: string) {
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
}

/**
 * 把对象转为formData
 */
export function objToFormData(obj: Recordable) {
  const formData = new FormData()
  Object.keys(obj).forEach((key) => {
    formData.append(key, obj[key])
  })
  return formData
}

export function isObject(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

export function isArray(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Array]'
}

export function isFunction(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Function]'
}

export function isString(obj: any) {
  return Object.prototype.toString.call(obj) === '[object String]'
}

export function isNumber(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Number]'
}

export function isBoolean(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Boolean]'
}

export function isJson(obj: any) {
  try {
    const o = JSON.parse(obj)
    return isObject(o) || isArray(o)
  } catch (e) {
    return false
  }
}

export function isDate(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Date]'
}

export function isEmail(value: any) {
  return !!value.match(/^[A-z0-9-_\.]+@[A-z0-9-_\.]+$/i)
}

export function isImage(value: string) {
  return !!value.match(/^[^\.]+.*\.+JPEG|JPG|GIF|PNG|BMP+$/i)
}

export function isHttp(value: string) {
  return !!value.match(/^http:\/\/.*/i)
}

export function isFile(value: any, extension: string) {
  if (isEmpty(value) || value.match(RegExp('^[^.]+.*.+(' + extension + ')+$', 'i'))) return true
  return isEmpty(extension)
}

export function isMobile(value: string) {
  return !!value.match(/^1\d{10}$/i)
}

/**
 * 判断对象是否为空
 * @param value
 */
export function isEmpty(value: any) {
  return (
    value === '' ||
    value == null ||
    typeof value == 'undefined' ||
    (isArray(value) && value.length === 0)
  )
}

export function debug(v: any, d?: number) {
  const depth = d === undefined ? 0 : d
  let str = v
  let delimiter: string = ''
  if (str == null) {
    str = 'null'
    if (depth > 0) {
      return str
    } else {
      alert(str)
      return
    }
  }

  if (isArray(v)) {
    str = 'array => ' + (v.length === 0 ? '' : '\n')
    delimiter = ''
    for (const i in v) {
      str += delimiter + '    '
      if (depth > 0) {
        for (let j = 0; j < depth; j++) str += '    '
      }
      str += '[' + i + '] = ' + (isString(v[i]) || isNumber(v[i]) ? v[i] : debug(v[i], depth + 1))
      delimiter = '\n'
    }
  } else if (isObject(v)) {
    str = 'object => \n'
    delimiter = ''
    for (const i in v) {
      str += delimiter + '    '
      if (depth > 0) {
        for (let j = 0; j < depth; j++) str += '    '
      }
      str += i + ': ' + (isString(v[i]) || isNumber(v[i]) ? v[i] : debug(v[i], depth + 1))
      delimiter = '\n'
    }
  }

  if (depth > 0) return str
  alert(str)
}

/**
 * 获取URL中的query参数值
 * @param name
 */
export function getQuery(name: string) {
  const reg: RegExp = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substring(1).match(reg) //获取url中"?"符后的字符串并正则匹配
  return r && !isEmpty(r) ? decodeURIComponent(r?.[2]) : ''
}

/**
 * 获取浏览器语言
 */
export function getBrowserLanguage() {
  return navigator.language.toLowerCase()
}

/**
 * 格式化json
 * @param json
 */
export function formatJson(json: string) {
  let ret = ''
  let pos = 0
  const length = json.length
  const indent = '&nbsp;&nbsp;&nbsp;&nbsp;'
  const newline = '<br/>'
  let prevChar = ''
  let outOfQuotes = true

  json = json.replace(/<xml>/g, '<xmp><xml>').replace(/<\/xml>/g, '</xml></xmp>')
  for (let i = 0; i <= length; i++) {
    const char = json.substr(i, 1)
    if (char === '"' && prevChar !== '\\') {
      outOfQuotes = !outOfQuotes
    } else if ((char === '}' || char === ']') && outOfQuotes) {
      ret += newline
      pos--
      for (let j = 0; j < pos; j++) {
        ret += indent
      }
    }

    ret += char

    if ((char === ',' || char === '{' || char === '[') && outOfQuotes) {
      ret += newline
      if (char === '{' || char === '[') {
        pos++
      }

      for (let j = 0; j < pos; j++) {
        ret += indent
      }
    }

    prevChar = char
  }
  return ret
}
